import { focusOnField } from 'components/form/utils/field-utils';
import { getInputLabel } from 'components/form/utils/form-utils';
import { getFocusPathFromFieldValues } from 'components/form/utils/input-utils';
import {
    Panel, panelHoverSx, PanePageButton, PaneProps, useWindowPageContext
} from 'components/wm';
import { PaneContentProps } from 'components/wm/types';
import RenderContextProvider from 'contexts/RenderContext';
import { FormikContextType, useFormikContext } from 'formik';
import { useFieldInfo } from 'contexts/FieldInfoContext';
import { useFormInfo } from 'hooks/form';
import { useTranslator } from 'hooks/translator';
import _ from 'lodash';
import React from 'react';
import { FieldInfo } from 'types/field';
import { SetState } from 'types/react';
import { isEmptyValue } from 'utils/utils';

import { Box, Card, FormControl, FormHelperText, FormLabel, Typography } from '@mui/material';

import { MultipleTableBasedOn } from './multiple';
import MultipleEditTable from './multiple/MultipleEditTable';
import MultipleMultablePane from './MultipleMutablePane';

type MultipleCreateProps = {
  pages: PaneProps[]
  setPages: SetState<PaneProps[]>
  formik: FormikContextType<unknown>
  info: FieldInfo 
  title: string
}

const MultipleImmutableField = () => {
  const onClick                                  = useEditMultipleOnClick()
  const { info, fieldProps }                     = useFieldInfo()
  const { t, translator }                        = useTranslator()
  const { processDefinition: { key: processKey}} = useFormInfo()
  const label                                    = getInputLabel(translator, info.field, processKey)

  // open a editable multiple in the right pane
  
  const helperText = fieldProps.error 
    ? (fieldProps.helperText?.trim() ? fieldProps.helperText : t('yup.invalid.multiple')) 
    : fieldProps.helperText

  return (
    <RenderContextProvider parent="multiple" >
      <FormControl fullWidth required={fieldProps.required} error={fieldProps.error}>
        <FormLabel sx={{ marginBottom: "16px" }} component="legend">{label}</FormLabel>

        <MultipleTableBasedOn />
        <Card className='multiple-field'
          sx={{
            ...panelHoverSx,
            cursor: "pointer",
            "&:hover": {
              "> .multiple-message": {
                height: "40px"
              },
              ...panelHoverSx['&:hover']
            }
          }}

          onClick={onClick}
        >
          <MultipleFieldPanel />
          <MultipleEditTable />
          <MultipleTableMessage />
        </Card>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </RenderContextProvider>
  );
}

const useEditMultipleOnClick = () => {
  const { info }                                 = useFieldInfo()
  const { setPages, pages }                      = useWindowPageContext()
  const { t, translator }                        = useTranslator()
  const { processDefinition: { key: processKey}} = useFormInfo()
  const label                                    = getInputLabel(translator, info.field, processKey) as string
  const formik                                   = useFormikContext()

  return () => onClickAddMultiple({pages, setPages, title: label, formik, info}) 
}

function onClickAddMultiple({pages, setPages, formik, info, title}: MultipleCreateProps) {
  const paneKey            = `multiple-${info.rpath}`
  const hasCurrentMultiple = pages.findIndex(page => page.id == paneKey) >= 0

  if (hasCurrentMultiple) {
    const autoFocus = async () => {
      const values = _.get(formik.values, info.rpath)
      const focusPath = getFocusPathFromFieldValues(info.field, values)

      if (focusPath) {
        const path = `${info.rpath}${focusPath}`
        focusOnField(path)
      }
    }

    autoFocus()
  } else {
    const multiplePane: PaneContentProps = {
      id: paneKey,
      title,
      content: <MultipleMultablePane info={info} />
    }
    const pane: PaneProps = { ...multiplePane, type: "content" }

    setPages(pages => [
      ...pages.filter(pane => pane.id != "multiple"),
      pane
    ])
  }
}


const MultipleTableMessage = () => {
  const { fieldProps } = useFieldInfo()
  const values         = fieldProps.value
  const { t }          = useTranslator()

  return (
    <Box className='multiple-message' 
      sx={{ 
        width: "100%", 
        display: "flex", 
        justifyContent: "center", 
        height: isEmptyValue(values) ? "40px" : "0px",
        transition: "height 0.3s ease"
      }}
    >
      <Typography variant='h5' color={theme => theme.palette.grey[400]} sx={{marginTop: "10px",marginBottom: "10px" }}>
        { isEmptyValue(values) 
          ? t("form.multiple.tableempty")
          : t("form.multiple.tableedit")
        }
      </Typography>
    </Box>
  )
}

const MultipleFieldPanel = () => {
  const { info } = useFieldInfo()
  const mode     = info.field.mode

  return (
    <Panel>
      <EditButton/>
      {/* mode == 'open' ? <EditButton/> : null */}
    </Panel>
  )
}

const EditButton = () => {
  const { t } = useTranslator()

  const onClick = (e: React.SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation()
    window.alert("edit")
  }

  return (
    <PanePageButton
      title={t('form.multiple.edit')}
      onClick={onClick}
      icon='Edit'
    />
  )
}



export default MultipleImmutableField
