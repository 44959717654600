import { RenderTableRowCells } from 'components/render';
import { MultipleRow } from 'types/multiple';

const MultipleTableBasedOnCells = ({index, rows}: {index: number, rows: MultipleRow[]}) => {
  const row = rows[index]

  const rowBasedOn = row.__basedOn
  const defaultBasedOn = rows[0].__basedOn
  if (rowBasedOn != undefined)
    return <RenderTableRowCells index={index} basedOn={rowBasedOn} empty={false} />
  else if (defaultBasedOn != undefined)
    return <RenderTableRowCells index={0} basedOn={defaultBasedOn} empty={true}/>
  else
    return null
}

export default MultipleTableBasedOnCells