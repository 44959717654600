import { useTranslation } from "react-i18next";
import { TimePicker, renderMultiSectionDigitalClockTimeView } from '@mui/x-date-pickers';
import { validateField } from "components/form/utils/validate-utils";
import { useFieldInfo } from "contexts/FieldInfoContext";
import { useFormInfo } from "hooks/form";
import InputError from "components/form/fields/InputError";
import { useAutoSubmitSignal } from "hooks/autosubmit";
import { fieldMinWidthStyle } from "components/form/utils/field-utils";
import { useConfig } from "hooks/config";


const InputTimeFieldContent = (props) =>  {
  const { setFocus, setViewOpen }      = props
  const { augProps, fieldProps, info } = useFieldInfo()
  const formInfo                       = useFormInfo()
  const { t }                          = useTranslation()
  const {signal}                       = useAutoSubmitSignal()
  const {props: {taskRendering}}       = useConfig()
  
  function toHelperText(error) {
    if (info.inMultiple)
      return undefined
    
    if (fieldProps.error)
      return fieldProps.helperText
    else if (error)
      return t("yup.invalid.time")
    else
    return " "
  }

  function handleValidate(e, value) {
    const error = validateField("time", fieldProps.required, e, value)
    augProps.setError(error)
  }

  function handleChange(value, context) {
    augProps.changeValue(value)
    
    const { validationError } = context
    augProps.setRuntimeError(validationError)
  }

  function handleBlur(e) {
    setFocus(false)
    fieldProps.onBlur(e)
    handleValidate(e, fieldProps.value)
    signal()
  }

  function handleFocus(e) {
    setFocus(true)
    fieldProps.onFocus(e)
  }

  const slotProps = {
    slotProps: {
      textField: (params) => ({
        ...fieldProps,
        onBlur: handleBlur,
        onFocus: handleFocus,
        ...params,
        id: fieldProps.id,
        error:  params.error || fieldProps.error,
        helperText :  toHelperText(params.error),
        style: taskRendering == 'standard' ? fieldMinWidthStyle(formInfo, info.field) : undefined,
        fullWidth: true
      }),
      actionBar: {actions: ['today','clear', 'cancel', 'accept']},
    }
  }

  return (
    <TimePicker
      className="grs-InputTimeField"
      format="HH:mm:ss"
      ampm={false}
      value={fieldProps.value}
      views={["hours", "minutes", "seconds"]} 
      viewRenderers={{
        hours: renderMultiSectionDigitalClockTimeView,
        minutes: renderMultiSectionDigitalClockTimeView,
        seconds:renderMultiSectionDigitalClockTimeView 
      }}

      onOpen={() => setViewOpen(true)}
      onClose={() => setViewOpen(false)}

      onChange={handleChange}
      {...augProps.inputProps}

      {...slotProps}
    />
  )
}

const InputTimeField = (props) => (
  <InputError>
    <InputTimeFieldContent />
  </InputError>
)

export default InputTimeField
