import { useFieldInfo } from 'contexts/FieldInfoContext';
import React, { useEffect, useRef, useState } from 'react';
import { SetState } from 'types/react';

import { Box, PopperPlacementType, Tooltip, Zoom } from '@mui/material';

const ErrorTooltip = ({children, error}: {children: React.ReactElement<any, any>, error: string}) => {
  const [hover, setHover]        = useState<boolean>(false)
  const [focus, setFocus]        = useState<boolean>(false)
  const [viewOpen, setViewOpen]  = useState<boolean>(false)
  const {info : { type } }       = useFieldInfo()

  const isSelect = type.includes("SELECT") || type.includes("DATE") || type.includes("TIME")
  const [placement, setPlacement] = useState(isSelect && focus ? "right" : "bottom-start")

  useEffect(() => {
    if (isSelect) {
      if (viewOpen)
        setPlacement("right")
      else
        setPlacement("bottom-start")
    }
  }, [viewOpen])

  const showError = (Boolean(hover) || Boolean(focus))
  const errorText = error ? error : ""

  return (
    <Tooltip
      placement={placement as any}
      open={showError}
      onClose={() => {setHover(false)}}
      onOpen={() =>  {setHover(true)}}
      TransitionComponent={Zoom}
      title={errorText}
      disableInteractive
      
    >
      <Box className={'error-tooltip'} sx={{display: "flex", flexGrow: 1}} >{ /* BUGFIX: this 'div' is required. it reseives the onMouse events, such that the on hover events of the tooltip work properly. */ }
        {React.cloneElement(children, { setFocus, setViewOpen })}
      </Box>
    </Tooltip>
  )
}

const InputError = ({children}: {children: React.ReactElement<any, any>}) => {
  const fieldInfo  = useFieldInfo()
  const inMultiple = fieldInfo.info.inMultiple
  const error      = fieldInfo.augProps.error

  if (!inMultiple)
    return React.cloneElement(children, { setFocus: () => {}, setViewOpen: () => {}})
  else
    return <ErrorTooltip error={error} children={children}/>
}

export type InputErrorProps = {
  setFocus: SetState<boolean>
  setViewOpen: SetState<boolean>
}

export default InputError
