import {useNavigate} from 'react-router'
import {useTranslation} from 'react-i18next'
import {useApolloClient, useMutation} from '@apollo/client'
import {
  getProcessName,
  getTaskCreatedAt,
  getTaskName,
  getSubject
} from 'components/form/utils/form-utils'
import LargeElevatedTable from 'components/tables/LargeElevatedTable'
import {CLAIM_TASK, GET_GROUP_TASKS} from 'queries/task'
import {bannerQueries} from 'utils/banner-utils'
import {useNotifier} from 'hooks/notification'
import {useResponsiveColumns} from 'hooks/columns'
import {useTranslator} from 'hooks/translator'
import {claimTask} from 'hooks/process'
import {getTaskTitle} from 'contexts/FormInfoContext'
import {createDataLoader} from 'utils/table'

const GroupTasksTable = () => {
  const client = useApolloClient()
  const [doClaimTask] = useMutation(CLAIM_TASK, {refetchQueries: bannerQueries})
  const navigate = useNavigate()
  const notifier = useNotifier()
  const columns = useColumns()
  const {translator} = useTranslator()
  const options = {translator, doClaimTask, navigate, notifier, register: true}

  const requestInfo = {
    variables: {},
    query:     GET_GROUP_TASKS,
    dataPath:  'data.groupTasks',
    countPath: 'data.groupTasksCount',
  }

  const toTableRow = (row) => toTableRowData(options, row)
  const dataLoader = createDataLoader(client, requestInfo, toTableRow)

  return <LargeElevatedTable
    description="group tasks"
    filterType="local"
    columns={columns}
    dataLoader={dataLoader}
  />
};

/* convert a tasks to row data structure */
function toTableRowData(options, task) {
  const {translator} = options
  const t = translator.translate.bind(translator)

  return {
    id: task.id,
    onClick: (e) => claimTask(options, e, task),
    cells: {
      'processName':  task.processDefinition ? getProcessName(translator, task) : t('unknown'),
      'taskName':     getTaskName(translator, task),
      'taskTitle':    getTaskTitle(translator, {isStartForm: false, task}),
      'taskSubject':  getSubject(task),
      'allowedRoles': task.allowedRoles,
      'createdAt':    getTaskCreatedAt(task)
      //"taskDescription": getTaskDescription(translator, task),
    }
  }
}

const useColumns = () => {
  const { t } = useTranslation()

  const processName = {
    headerName: t('process_name'),
    field: "processName",
    cellProps: {
      width: 200,
      align: "left"
    }
  }

  const subject = {
    headerName: t('subject'),
    field: "taskSubject",
    cellProps: {
      width: 200,
      align: "left"
    }
  }  

  const process_step = {
    headerName: t('menu.process_step'),
    field: "taskTitle",
    cellProps: {
      width: 200,
      align: "left"
    }
  }

  const description = {
    headerName: t('description'),
    field: "taskDescription",
    cellProps: {
      width: 350,
      align: "left"
    }
  }

  const createdAt = {
    headerName: t('createdAt'),
    field: "createdAt",
    cellProps: {
      width: 100,
      align: "left",
      sx: {whiteSpace: "nowrap"}
    }
  }

  const allowedRoles = {
    headerName: t('allowed_roles'),
    field: "allowedRoled",
    cellProps: {
      width: 200,
      align: "left"
    }
  }
  
  const columns = {
    xxs: [subject],
    md: [
      processName,
      subject 
    ],
    lg: [
      processName,
      subject,
      process_step,
    ],
    xl: [
      processName,
      subject,
      process_step,
      createdAt,
      allowedRoles
    ]
  }

  return useResponsiveColumns(columns, true)
}

export default GroupTasksTable;
