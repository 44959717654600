import _ from 'lodash';
import { useState } from 'react';
import { SetState } from 'types/react';
import { alpha, Box, SxProps } from '@mui/material';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';

import { useFieldInfo } from 'contexts/FieldInfoContext';
import { RenderValue } from 'components/render';
import MultipleDragButton from 'components/form/fields/multiple/MultipleDragButton';
import { getDragRowStyle, moveDragRow } from 'components/form/fields/multiple/MultipleTable';
import { MultipleRowCheckBox } from 'components/form/fields/multiple/MultipleTableRowSelectCell';
import { useMultipleColumns } from 'components/form/fields/utils/multiple-utils';
import StickyBannerLayout from 'components/layout/common/StickyBannerLayout';
import { Field } from 'types/graphql';
import Types from 'types/types';
import { fallback } from 'utils/utils';
import { MultipleColumn, MultipleRow, MultipleRows } from 'types/multiple';

import InputField from '../InputField';
import AddRowButton from './AddRowButton';
import CopyRowButton from './CopyRowButton';
import DeleteRowButton from './DeleteRowButton';
import MultipleTableHeaderActions from './MultipleTableHeaderActions';
import { MultipleCheckBox } from './MultipleTableHeaderSelectCell';
import { MultipleFeatures, MutableMultipleProps } from '../InputMultipleField';

type WithRowIndex = {
  index: number
}

type WithRow = {
  row: MultipleRow
}

type WithRows = {
  rows: MultipleRows
}

type WithColums = {
  columns: MultipleColumn[]
}

export type SelectedRows = number[] 

type SelectRows = {
  setSelected: SetState<SelectedRows>
  selected: SelectedRows
}

interface FlexRowsProps extends SelectRows, WithRows {}

interface FlexHeaderProps extends FlexRowsProps {
  features: MultipleFeatures
}

interface FlexRowProps extends SelectRows, WithRow, WithRowIndex, WithColums, WithRows {}

interface FlexRowActionProps extends SelectRows, WithRowIndex {}

interface FlexRowsActionProps extends SelectRows, WithRowIndex, WithRows {}

interface FlexRowContentProps extends WithRow, WithRowIndex, WithColums {}

const MultipleFlex = ({ features }: MutableMultipleProps) => {
  const { fieldProps } = useFieldInfo()

  const rows = fallback(fieldProps.value, [{}])
  const [ selected, setSelected ] = useState<SelectedRows>([])

  return (
    <Box className='multiple-flex-table' sx={{display: "flex", width: "100%", maxHeight: "100%", overflow: 'auto', flexDirection: "column"}}>
      <StickyBannerLayout
        banner={<MultipleFlexHeader features={features} rows={rows} selected={selected} setSelected={setSelected} />}
        bodySx={{display: "flex", flexDirection: "column", paddingTop: "4px"}}
      >
        <MultipleFlexRows rows={rows} selected={selected} setSelected={setSelected} />
      </StickyBannerLayout>
    </Box>
  )
}

const MultipleFlexHeader = ({features, rows, selected, setSelected}: FlexHeaderProps) => {
  const { info } = useFieldInfo()
  const mode     = info.field.mode

  if (mode === "open") {
    return (
      <Box sx={{ display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center", background: theme => alpha(theme.palette.grey[400], 0.3) }}>
        <MultipleCheckBox rows={rows} selected={selected} setSelected={setSelected}/>
        <MultipleTableHeaderActions
          features={features}
          rows={rows}
          selected={selected}
          setSelected={setSelected}
        />
      </Box>
    )
  } else return null
}

const MultipleFlexRows = ({rows, selected, setSelected}: FlexRowsProps) => {
  const columns = useMultipleColumns(rows)
  const { info, augProps, fieldProps } = useFieldInfo()

  const onDragAndDrop = (result: any) => {
    if (!result.destination) {
      return;
    }

    const from = result.source.index
    const to   = result.destination.index
   
    const newRows = moveDragRow(rows, from, to)
    augProps.setValue(newRows)
  };


  return (
    <DragDropContext onDragEnd={onDragAndDrop}>
      <Droppable droppableId={`drop-${info.rpath}`}>
        {(provided: any, snapshot: any) => (
          <Box sx={{ width: "100%" }} {...provided.droppableProps} ref={provided.innerRef} >
            { rows
              .map((row, index) =>
                <MultipleFlexRow rows={rows} columns={columns} row={row} key={index} selected={selected} setSelected={setSelected} index={index} />
              )
            }
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  )
}

const MultipleFlexRow = ({columns, index, rows, row, selected, setSelected}: FlexRowProps): JSX.Element => {
  return (
    <Draggable
      key={index}
      draggableId={"q-" + index}
      index={index}
    >
      {(provided: any, snapshot: any) => (
        <Box 
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}

          style={getDragRowStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
          className='multiple-flex-row' 
          sx={{ paddingY: "8px", borderBottom: "2px solid", borderColor: theme => theme.palette.grey[300], display: "flex", flexGrow: 1, flexDirection: "row" }}
        >
          <MultipleFlexRowPreActions index={index} selected={selected} setSelected={setSelected} />
          <MultipleFlewRowFields columns={columns} row={row} index={index} />
          <MultipleFlexRowPostActions rows={rows} index={index} selected={selected} setSelected={setSelected} />
        </Box>
      )}
    </Draggable>
  )
}

const MultipleFlewRowFields = ({columns, row, index}: FlexRowContentProps) => {
  const { info, info: {indices} } = useFieldInfo()
  const newIndices                = { ...indices, [info.field.elementName as string]: index }

  return (
    <Box className='multiple-flex-fields' sx={{display: "flex", flexDirection: "row", justifyContent: 'space-between', gap: "10px", flexGrow: 999, overflow: "visible", flexWrap: "wrap", maxWidth: "100%"}}>
      { columns
          .map((column, index) => 
            <MultipleFlewRowField key={index} column={column} indices={newIndices} row={row} index={index} /> 
          )
      }
    </Box> 
  )
}

interface MultipleRowFieldProps extends WithRowIndex,WithRow {
  column: MultipleColumn
  indices: any
}

const MultipleFlewRowField = ({column, row, index, indices}: MultipleRowFieldProps): JSX.Element => {
  if (column.type == 'input')  {
     
    return (
      <Box className='multiple-flex-field' sx={{display: "flex", ...flexInputFieldSize(column)}} >
        <InputField
          field={column.field as Field}
          indices={indices}
        />
      </Box>
    )
  } else {
    const value = _.get(row, column.attr)
    return (
      <Box 
        sx={{ 
          background: theme => alpha(theme.palette.grey[100], 0.5), 
          height: "100%", 
          display: "flex", 
          alignItems: "center",
          paddingX: "5px"
        }} 
      >
        <RenderValue>
          {value}
        </RenderValue>
      </Box>
    )
  }

}

function flexInputFieldSize(column: MultipleColumn): SxProps {

  switch (column.renderType) {
    case "DECIMAL":
    case "INTEGER":
      return {
        flexGrow: 1,
        minWidth: "120px",
        width: "120px",
      }

    case "MULTIPLE SELECT":
      return {
        flexGrow: 4,
        minWidth: "240px",
        width: "240px",
      }

    case column.field?.type == "BOOLEAN" && "SINGLE SELECT":
      return {
        flexGrow: 1,
        minWidth: "120px",
        width: "120px",
      }

    case "SINGLE SELECT":
      return {
        flexGrow: 2,
        minWidth: "240px",
        width: "240px",
      }

    case 'DATETIME':
      return {
        flexGrow: 1,
        minWidth: "240px",
        width: "240px",
      }

    case 'TIME':
    case 'DATE':
      return {
        flexGrow: 1,
        minWidth: "180px",
        width: "180px",
      }

    case "FILE":
      return {
        flexGrow: 1,
        minWidth: "240px",
        width: "240px",
      }


    case "PERIOD":
      return {
        flexGrow: 2,
        minWidth: "240px",
        width: "240px",
      }

    case "TEXT":
      return {
        flexGrow: 1,
        minWidth: "180px",
        width: "180px",
      }


    case 'MULTIPLE':
      return {
        flexGrow: 99999,
        width: "100%", 
        minWidth: "100%"
      }

    default:
      return {
        flexGrow: 1,
        minWidth: "100px",
        width: "100px",
      }
  }
}

const MultipleFlexRowPreActions = ({selected, setSelected, index}: FlexRowActionProps): JSX.Element => {
  const { info } = useFieldInfo()
  const mode     = info.field.mode

  return (
    <Box className='multiple-flex-pre-actions' sx={{display: "flex", height: "100%", justifyContent: "center"}}>
      { mode === "open"
        ? <MultipleRowCheckBox index={index} selected={selected} setSelected={setSelected} />
        : Types.null<JSX.Element>()
      }
    </Box>
  )
}

const MultipleFlexRowPostActions = ({selected, setSelected, index, rows}: FlexRowsActionProps): JSX.Element => {
  const { info } = useFieldInfo()
  const mode     = info.field.mode

  return (
    <Box className='multiple-flex-post-actions' sx={{display: "flex", flexShrink: 1, flexDirection: "row", alignItems: "center"}}>
      { mode === "open"
        ? <Box sx={{display: "flex", flexShrink: 1, flexDirection: "row", flexWrap: 'wrap'}} >
            <AddRowButton index={index} rows={rows} selected={selected} setSelected={setSelected} />
            <CopyRowButton index={index} rows={rows} selected={selected} setSelected={setSelected} />
            <DeleteRowButton index={index} rows={rows} selected={selected} setSelected={setSelected} />
            <MultipleDragButton/>
          </Box>
        : Types.null<JSX.Element>()
      }
    </Box>
  )}

export default MultipleFlex
