import { TextField } from '@mui/material'
import InputError, { InputErrorProps } from 'components/form/fields/InputError'
import { fieldMinWidthStyle } from 'components/form/utils/field-utils'
import { validateField, workaroundFieldLabelOverlap } from 'components/form/utils/validate-utils'
import { useAutoSubmitSignal } from 'hooks/autosubmit'
import { useConfig } from 'hooks/config'
import { useFieldInfo } from 'contexts/FieldInfoContext'
import { useFormInfo } from 'hooks/form'
import { isEmptyValue } from 'utils/utils'

type DecimalSeparator = "dot" | "comma";

const InputDecimalField = () => (
  <InputError>
    {/* @ts-ignore */}
    <InputDecimalFieldContent />
  </InputError>
)

const InputDecimalFieldContent = (props: InputErrorProps) => {
  const { setFocus }                   = props
  const { fieldProps, augProps, info } = useFieldInfo()
  const formInfo                       = useFormInfo()
  const {signal}                       = useAutoSubmitSignal()
  const {props: {taskRendering}}       = useConfig()

  function handleValidate(e: React.SyntheticEvent) {
    const error = validateField("decimal", fieldProps.required, e)
    augProps.setError(error)
  }

  function handleChange (e: React.SyntheticEvent) {
    fieldProps.onChange(e)
    handleValidate(e)
	}

  function handleBlur (e: React.SyntheticEvent) {
    setFocus(false)
    fieldProps.onBlur(e)
    handleValidate(e)
    signal()
  }

  function handleFocus(e: React.SyntheticEvent) {
    setFocus(true)
    fieldProps.onFocus(e)
  }

  const workaround = workaroundFieldLabelOverlap(info.rpath)
  return (
    <TextField
      {...fieldProps}
      {...workaround}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      style={taskRendering == 'standard' ? fieldMinWidthStyle(formInfo, info.field, taskRendering) : undefined}
      fullWidth
    />
  )
}

export default InputDecimalField
