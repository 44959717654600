import React              from 'react'
import RenderTableCell    from 'components/render/RenderTableCell'
import { basedOnEntries } from 'components/form/utils/form-utils'
import { useNotifier }    from 'hooks/notification'
import Types              from 'types/types'

type RenderProps = {
  index: number
  basedOn: any
  empty: boolean
}

const RenderTableRowCells = ({index, basedOn, empty}: RenderProps) => {
  const notifier = useNotifier()

  const createEntries = (value: any) => {
    if (Types.isTuple(value)) {
      return basedOnEntries(value)
    }

    if (Types.isGearsObj(value) && value.__text) {
      return [["label", value.__text]]
    }

    if (Types.isObject(value))
      return basedOnEntries(value)

    if (!Types.isStructure(value))
      return [['key', value]]

    if (Array.isArray(value) && value.length == 2)
      return [value]

    notifier.error("Not a suitable format provided for rendering a table row")
    console.error("Not a suitable format provided for rendering a table row at index %d: %o", index, value)
    return []
  }

  const entries = createEntries(basedOn)
  return (
    <React.Fragment>
      {
        entries.map(([_key, value], columnIndex) => (
          <RenderTableCell
            basedOn={empty ? null : value}
            style={{ whiteSpace: 'nowrap' }}
            key={index + "_" + columnIndex}
            rowIndex={index}
            columnIndex={columnIndex}
          />
        ))
      }
    </React.Fragment>
  )
}

export default RenderTableRowCells
