import { useContext }  from "react";
import { createContext }       from 'react';
import merge                   from 'deepmerge'
import { useFormInfo }         from 'hooks/form';
import { 
  toValuesPath, 
  collectDefaults, 
  enrichValues,
  collectAdditionalValues, 
  collectAdditionalFromSaved}              from 'components/form/utils/field-utils';
import { getPath } from 'utils/utils';

export const useValues = () => useContext(ValuesContext)

const ValuesContext = createContext();

const ValuesProvider = ({children}) => {
  const formInfo = useFormInfo()
  const values   = createValues(formInfo)

  return (
    <ValuesContext.Provider value={{ values, createRow }}>
      {children}
    </ValuesContext.Provider>
  )
}

const overwriteMerge = (_destinationArray, sourceArray, _options) => sourceArray

// based-ons and such
export function addAdditionalValues(formInfo, values) {
  function nonEmptyObject(obj) {
    return Object.keys(obj).length > 0
  }

  // if the form has saved values, collect additional values from those instead
  const usingSavedValues = nonEmptyObject(formInfo.form.saved)
  const lookupValues     = usingSavedValues ? formInfo.form.saved : formInfo.form.values
  const actualValues     = Array.isArray(lookupValues) ? lookupValues[0] : lookupValues
  const additionalValues = usingSavedValues ? collectAdditionalFromSaved(actualValues) : collectAdditionalValues(actualValues)
  const enrichedValues   = enrichValues(values, additionalValues)

  console.debug("ValuesProvider.createValues: additionalValues=%o", additionalValues)

  return enrichedValues
}

export function createValues(formInfo) {
  const defaultValues = collectDefaults(formInfo.form.values, "", formInfo, true)
  const prefillValues = formInfo.kind === "PROCESS_FILL" ? formInfo.prefill : {}
  const savedValues   = formInfo.form.saved
  const mergedValues  = merge.all([defaultValues, prefillValues, savedValues], { arrayMerge: overwriteMerge })
  const values        = addAdditionalValues(formInfo, mergedValues)

  console.debug("ValuesProvider.createValues: defaultValues=%o\nprefillValues=%o\nsavedValues=%o\nmergedValues=%o\nresult=%o", 
    defaultValues, prefillValues, savedValues, mergedValues, values)

  return values
}

export function createRow(vpath, formInfo) {
  const valuesPath    = toValuesPath(formInfo, vpath)
  console.log("vpath=%s, valuesPath=%s, values=%o, res=%o", vpath, valuesPath, formInfo.form.values, getPath(formInfo.form.values, valuesPath))
  const defaultValues = collectDefaults(getPath(formInfo.form.values, valuesPath), valuesPath, formInfo, false)
  const rowValues     = Array.isArray(defaultValues) ? defaultValues[0] : defaultValues

  console.debug("ValuesProvider.createRow: vpath=%s, valuesPath=%s\ndefaultValues=%o\nrowValues=%o", 
    vpath, valuesPath, defaultValues, rowValues)

  return rowValues
}

export default ValuesProvider;
