import { fallback, } from 'utils/utils';
import { Box } from '@mui/material';
import _ from 'lodash';
import { RenderValue } from 'components/render';
import { useFieldInfo } from 'contexts/FieldInfoContext';

const MultipleTableBasedOn = ({templateRow}: any) => {
  const { fieldProps, info } = useFieldInfo()
  const rows                 = fallback(fieldProps.value, [{}])
  const openBasedOn          = templateRow !== undefined
      ? templateRow.__basedOn
      : rows.length == 1 && rows[0].__basedOn || {}

  return info.field.mode === "open" && !_.isEmpty(openBasedOn)
    ? <Box id={"based-on-content"} marginBottom={"10px"} >
        <RenderValue>
          {openBasedOn}
        </RenderValue>
      </Box>
    : null
}

export default MultipleTableBasedOn
