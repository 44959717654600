import _ from 'lodash'
import { SxProps } from '@mui/material'

import RenderFiles from 'components/render/RenderFiles'
import RenderPeriodObject from 'components/render/RenderPeriodObject'
import { DisplayProps } from 'components/render/utils/render'
import RenderContextProvider from 'contexts/RenderContext'
import { useNotifier } from 'hooks/notification'
import Types from 'types/types'
import { env } from 'utils/utils'

import { RenderGearsValue } from './RenderGearsValue'
import { RenderSimpleValue } from './RenderSimpleValue'
import { RenderStructureValue } from './RenderStructureValue'

export const ENABLE_HTML = env('ENABLE_HTML') == "true"

type RenderProps = {
  value?: any
  children?: React.ReactNode
  sx?: SxProps
  displayProps?: DisplayProps
}

const RenderValue = ({value, children, sx, displayProps}: RenderProps) => (
  <RenderContextProvider props={{sx}}>
    <RenderAnyValue value={children == undefined ? value : children} displayProps={displayProps}/>
  </RenderContextProvider>
)

type RenderAnyValueProps = {
  value: any
  level?: number
  displayProps?: DisplayProps
}

const RenderAnyValue = ({value, displayProps, level = 0}: RenderAnyValueProps) => {
  const notifier = useNotifier()
   
  switch (true) {
    case value == undefined || value == null || (Array.isArray(value) && value.length === 0): 
      return null

    case Types.isOption(value): 
      return <RenderValue value={value.label} displayProps={displayProps}/>

    case Types.isSimpleType(value) || level > 5:
      return <RenderSimpleValue value={value} displayProps={displayProps}/>
    
    case Types.isComponent(value):
      return value

    case Types.isGearsType(value):
      return <RenderGearsValue value={value} />

    // TODO is dit niet dubbelop? RenderFiles zit ook is RenderGearsValue
    case Types.isFileRefs(value):
      return <RenderFiles attachments={value} />    

    case Types.isPeriodObject(value): 
      return <RenderPeriodObject value={value}/>

    case Types.isStructure(value):
      return <RenderStructureValue value={value} />

    default: 
      notifier.error("Could not render: " + value)
      return null
  }
}

export default RenderValue
