import { useTranslation } from "react-i18next";
import { DesktopDateTimePicker, renderMultiSectionDigitalClockTimeView } from '@mui/x-date-pickers';
import { validateField } from "components/form/utils/validate-utils";
import InputError from "components/form/fields/InputError";
import { renderDateDay } from 'components/form/fields/date/DayDate';
import { fieldMinWidthStyle } from 'components/form/utils/field-utils';
import { useFieldInfo } from 'contexts/FieldInfoContext';
import { useFormInfo } from 'hooks/form';
import { useAutoSubmitSignal } from 'hooks/autosubmit';
import { useConfig } from 'hooks/config';


const InputDateTimeFieldContent = (props) => {
  const { setFocus, setViewOpen }      = props
  const { augProps, fieldProps, info } = useFieldInfo()
  const formInfo                       = useFormInfo()
  const { t }                          = useTranslation()
  const {signal}                       = useAutoSubmitSignal()
  const {props: {taskRendering}}       = useConfig()
  
  function toHelperText(error) {
    if (info.inMultiple)
      return undefined
    
    if (fieldProps.error)
      return fieldProps.helperText
    else if (error)
      return t("yup.invalid.datetime")
    else
    return " "
  }

  function handleValidate(e, value) {
    const error = validateField("datetime", fieldProps.required, e, value)
    augProps.setError(error)
  }

  function handleChange(value, context) {
    augProps.changeValue(value)
    
    const { validationError } = context
    augProps.setRuntimeError(validationError)
  }

  function handleBlur(e) {
    setFocus(false)
    fieldProps.onBlur(e)
    handleValidate(e, fieldProps.value)
    signal()
  }

  function handleFocus(e) {
    setFocus(true)
    fieldProps.onFocus(e)
  }

  const slotProps = {
    slotProps: {
      textField: (params) => ({
        ...fieldProps,
        onBlur: handleBlur,
        onFocus: handleFocus,
        ...params,

        id: fieldProps.id,
        error:  params.error || fieldProps.error,
        helperText :  toHelperText(params.error),
        style: taskRendering == 'standard' ? fieldMinWidthStyle(formInfo, info.field) : undefined,

        fullWidth: true,
      }),
      actionBar: {actions: ['today','clear', 'cancel', 'accept']},
    }
  }

  return (
    <DesktopDateTimePicker
      displayWeekNumber={true}
      desktopModeMediaQuery="@media (not (pointer: coarse))"
      className="grs-InputDateTimeField"
      format="dd-MM-yyyy HH:mm:ss"
      ampm={false}
      ampmInClock={false}
      renderDay={renderDateDay}
      value={fieldProps.value}
      onOpen={() => setViewOpen(true) }
      onClose={() => setViewOpen(false)}

      views={["year", "month","day", "hours", "minutes", "seconds"]}
      viewRenderers={{
        hours: renderMultiSectionDigitalClockTimeView,
        minutes: renderMultiSectionDigitalClockTimeView,
        seconds: renderMultiSectionDigitalClockTimeView,
      }}

      onChange={handleChange}
      {...augProps.inputProps}

      {...slotProps}
    />
  )
}

const InputDateTimeField = (props) => (
  <InputError>
    <InputDateTimeFieldContent />
  </InputError>
)

export default InputDateTimeField
