import { useTranslation }      from "react-i18next";
import { DatePicker }          from '@mui/x-date-pickers';
import { fieldMinWidthStyle }  from 'components/form/utils/field-utils';
import { validateField }       from "components/form/utils/validate-utils";
import { renderDateDay }       from 'components/form/fields/date/DayDate';
import InputError              from "components/form/fields/InputError";
import { useFieldInfo }        from 'contexts/FieldInfoContext';
import { useFormInfo }         from 'hooks/form';
import { useAutoSubmitSignal } from 'hooks/autosubmit';
import { useConfig }           from 'hooks/config';

const InputDateFieldContent = (props) => {
  const { setFocus, setViewOpen }      = props
  const { augProps, fieldProps, info } = useFieldInfo()
  const formInfo                       = useFormInfo()
  const { t }                          = useTranslation()
  const {signal}                       = useAutoSubmitSignal()
  const {props: {taskRendering}}       = useConfig()
  // const [date, setDate]                = useState(() => fieldProps.value)

  // const notifier                       = useNotifier()

  // console.log("For %s found date %s but has value %s", info.rpath, fieldProps.value, date)

  // delete fieldProps.onChange
  
  function toHelperText(error) {
    if (info.inMultiple)
      return undefined
    
    if (fieldProps.error)
      return fieldProps.helperText
    else if (error)
      return t("yup.invalid.date")
    else
    return " "
  }

  function handleValidate(e, value) {
    const error = validateField("date", fieldProps.required, e, value)
    augProps.setError(error)
  }

  function handleChange(value, context) {
    augProps.changeValue(value)
    // setDate(value)
    
    const { validationError } = context
    augProps.setRuntimeError(validationError)
  }

  function handleBlur(e) {
    setFocus(false)
    fieldProps.onBlur(e)
    handleValidate(e, fieldProps.value)
    signal()
  }

  function handleFocus(e) {
    setFocus(true)
    fieldProps.onFocus(e)
  }

  const slotProps = {
    slotProps: {
      textField: (params) => ({
        ...fieldProps,
        onBlur: handleBlur,
        onFocus: handleFocus,
        ...params,
        id: fieldProps.id,
        error: params.error || fieldProps.error,
        helperText: toHelperText(params.error),
        style: taskRendering == 'standard' ? fieldMinWidthStyle(formInfo, info.field) : undefined,
        fullWidth: true
      }),
      actionBar: {actions: ['today','clear', 'cancel', 'accept']},
    }
  }

  // useEffect(() => setDate(fieldProps.value), [fieldProps.value])

  return <DatePicker
    displayWeekNumber={true}
    className="grs-InputDateField"
    desktopModeMediaQuery="@media (not (pointer: coarse))"
    renderDay={renderDateDay}
    format="dd-MM-yyyy"
    // views={["month", "day"]}
    value={fieldProps.value}
    onChange={handleChange}
    {...augProps.inputProps}

    onOpen={() => setViewOpen(true)}
    onClose={() => setViewOpen(false)}
      
    {...slotProps}
  />
}

const InputDateField = (props) => (
  <InputError>
    <InputDateFieldContent />
  </InputError>
)

export default InputDateField
