import _                                from "lodash";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  Box }                                 from "@mui/material";
import { formatChoices }                from "components/form/utils/input-utils";
import { getEventValue, validateField } from "components/form/utils/validate-utils";
import { useFieldInfo }                 from "contexts/FieldInfoContext";
import { isOption }                     from "utils/option-utils";
import { useTranslator }                from "hooks/translator";
import { useFormInfo }                  from "hooks/form";
import { useAutoSubmitSignal }          from "hooks/autosubmit";

const InputRadioField = () => {
  const { fieldProps, info, augProps } = useFieldInfo()
  const formInfo       = useFormInfo()
  const { translator } = useTranslator()
  const { signal }     = useAutoSubmitSignal()
  const options        = formatChoices(translator, info.options, info.optionsKind, formInfo.processDefinition.key)
  const booleanMode    = info.type === "RADIO BOOLEAN SELECT"
  const currentValue   = isOption(fieldProps.value) ? fieldProps.value.value : fieldProps.value

  function handleChange(e) {
    augProps.setRuntimeError(null)
    const value = getOptionValue(e)
    const isOptionalInput = !fieldProps.required
    // optional inputs can be de-selected if the user pressed the selected value
    const newValue = isOptionalInput && currentValue === value.value ? null : value
    augProps.setValue(newValue)
    validate(e, newValue)
  }

  function getOptionValue(e) {
    const value = getEventValue(e, null)
    // in boolean mode only return true/false, otherwise { key, value }
    return booleanMode ? value : getOptionByValue(value)
  }

  function getOptionByValue(value) {
    return options.find(option => option.value === value)
  }

  function handleBlur(e) {
    fieldProps.onBlur(e)
    validate(e, fieldProps.value)
    signal()
  }

  function validate(e, value) {
    augProps.setError(validateField("radio", fieldProps.required, e, value))
  }

	return (
    <>
      { !info.inMultiple ? <Box sx={{mt:2}}/> : null }
      <FormControl id={fieldProps.id} component="fieldset" required={fieldProps.required} error={fieldProps.error}>
        {!info.inMultiple ? <FormLabel component="legend">{fieldProps.label}</FormLabel> : null}
        <RadioGroup
          row={booleanMode}
          name={fieldProps.name}
          value={currentValue}
          onBlur={handleBlur}
          onFocus={fieldProps.onFocus}
        >
          <RadioOptions options={options} onChange={handleChange}/>
        </RadioGroup>
        <FormHelperText>{fieldProps.helperText}</FormHelperText>
      </FormControl>
    </>
  );
}

const RadioOptions = ({ options, onChange }) => {
  return options.map((option, index) =>
    <FormControlLabel
      onClick={onChange}
      key={index}
      value={option.value}
      label={option.label}
      control={<Radio />}
    />
  )
}

export default InputRadioField
