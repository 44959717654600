import { useFormikContext } from 'formik';
import { useFieldInfo } from 'contexts/FieldInfoContext';
import { useSubmitOptions } from 'hooks/options';
import { useSubmitting } from 'hooks/submitting';
import { Fragment, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { Option } from 'types/option';
import { toOption } from 'utils/option-utils';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, ButtonGroup, Fade, FormControl, Typography, useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';

const InputSingleSelectSubmit = () => {
  return (
    <Box sx={{width: "100%", maxWidth: "100%", marginTop: "15px", overflow: 'visible'}}>
      <InputSelectButton/>
    </Box>
  )
}

const InputSelectButton = () => {
  const options   = useSubmitOptions()
  // @ts-ignore
  const sm = useMediaQuery(theme => theme?.breakpoints?.up('md'));

  if (sm && options.length <= 5) {
    return <InputSelectSubmit options={options}/>
  } else {
    return <InputLargeSelectSubmit options={options}/>
  }
}

const InputSelectSubmit = ({options}: {options: Option[]}) => {
  const { info, fieldProps }      = useFieldInfo()
  const currentOption             = fieldProps.value
  const {submitting}              = useSubmitting()
  const [loadingId, setLoadingId] = useState<string | undefined>(undefined)
  const formik                    = useFormikContext();

  function getButtonId(index: number) {
    return `${info.rpath}-${index}`
  }

  useEffect(() => {
    if (!submitting) 
      setLoadingId(undefined)
  }, [submitting])

  const onClick = (index: number, option: Option) => (e:SyntheticEvent) => {
    const opt = option.value == 'submit' ? null : option
    formik.setFieldValue(info.rpath, opt)
    setLoadingId(getButtonId(index))
    formik.submitForm()
  }

  return (
    <FormControl id={fieldProps.id} component="fieldset" required={fieldProps.required} sx={{ width: "100%"}}>
      <ButtonGroup variant='contained' fullWidth>
        { options
          .map((option, index) => (
            <SubmitSelectButton
              key={index}
              id={getButtonId(index)}
              onClick={onClick(index, option)}
              currentOption={currentOption}
              option={option}
              loading={submitting}
              loadingId={loadingId}
            />
          ))
        }
      </ButtonGroup>
    </FormControl>
  )
}

const InputLargeSelectSubmit = ({options}: {options: Option[]}) => {
  const { info, fieldProps }              = useFieldInfo()
  const [open, setOpen]                   = useState(false);
  const anchorRef                         = useRef<any>(null);
  const currentOption                     = fieldProps.value
  const { submitting}                     = useSubmitting()
  const [loadingId, setLoadingId]         = useState<string | undefined>(undefined)
  const formik                            = useFormikContext();
  const id: string                        = info.rpath
  const [selectedIndex, setSelectedIndex] = useState(() => getInitialIndex(options, currentOption))

  const handleClick = (option: Option) => () => {
    const opt = option.value == 'submit' ? null : option
    formik.setFieldValue(info.rpath, opt)
    setLoadingId(id)
    formik.submitForm()
  }

  useEffect(() => {
    if (!submitting)
      setLoadingId(undefined)
  },[submitting])

  const handleMenuItemClick = (
    _event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index);
    setOpen(false);
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  }

  const handleClose = (event: Event) => {
    if ( anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) 
      return;

    setOpen(false);
  };

  return (
    <Fragment>
      <ButtonGroup variant="contained" sx={{width: "100%"}} fullWidth>
        <SubmitSelectButton 
          id={id}
          currentOption={currentOption}
          onClick={handleClick(options[selectedIndex])} 
          option={options[selectedIndex]} 
          loading={submitting}
          loadingId={loadingId}
        />
        <Button
          disabled={submitting}
          ref={anchorRef}
          size="small"
          onClick={handleToggle}
          sx={{width: "40px"}}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        placement='top-end'
        sx={{
          //maxHeight: "200px", overflow: "auto",
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Fade
            timeout={350}
            {...TransitionProps}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList sx={{maxHeight: "300px", overflow: "auto"}} id="split-button-menu" autoFocusItem>
                  { options
                    .map((option, index) => (
                      <MenuItem
                        key={index}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        {option.label}
                      </MenuItem>
                    ))
                  }
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </Fragment>
  )
}

function getInitialIndex(options: Option[], currentOption?: Option) {
  const option = toOption(currentOption)
  if (option) {
    const index = options.findIndex(op => op.value == option.value)
    if (index >= 0)
      return index
  }

  const index = options.findIndex(op => op.value == 'submit')
  return index >= 0 ? index : 0
}

type SubmitButtonProps = {
  id :            string
  currentOption?: Option
  option :        Option
  onClick :       (e: SyntheticEvent) => void
  loadingId :     string | undefined
  loading :       boolean
}

const SubmitSelectButton = ({id, currentOption, option, onClick, loading, loadingId}: SubmitButtonProps) => {
  return (
    <LoadingButton
      id={id}
      loading={loading && loadingId == id}
      disabled={loading}
      color="primary"
      fullWidth
      size="large"
      variant="contained"
      onClick={onClick}
      value={option.value}
    >
      <Typography  sx={{maxWidth: "100%", display: 'block', 
        fontWeight: 500,
        fontSize: '0.9375rem',
        lineHeight: 1.75,
        letterSpacing: '0.02857em',
        textTransform: 'uppercase', 
        wordWrap: "break-word", 
        whiteSpace: "wrap", 
        overflowWrap: "break-word"      
      }}>
        {option.label}
      </Typography>
    </LoadingButton>
  )
}

export default InputSingleSelectSubmit
