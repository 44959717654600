import { F } from 'helpers/formatter'
import { t }                 from './i18n'
import _                     from "lodash"
import { getForName, getInputLabel } from 'components/form/utils/form-utils'

// in: field array
// out: [ array of multple fields, array of other fields ]
function partitionMultiple(fields) {
  const partition = (ary, callback) =>
    ary.reduce((acc, e) => {
      acc[callback(e) ? 0 : 1].push(e)
      return acc
    }, [[], []])

  return partition(fields, field => field.kind === "multiple")
}

function groupByArray(xs, key) {
  return xs.reduce(function (rv, x)
    {
      let v = key instanceof Function ? key(x) : x[key];
      let el = rv.find((r) => r && r.key === v);
      if (el) {
        el.values.push(x);
      } else {
        rv.push({ key: v, values: [x] });
      }
      return rv;
    }, []);
}

// create a task description from a submit response
export function createTaskDescription(translator, task) {
  const documentation = task.documentation
  const processKey = task.processDefinition.key

  function toAttributeLabel(field, translator) {
    return getInputLabel(translator, field, processKey)
  }

  function toAttributesDescription(inputFields, translator) {
    return F.toList(inputFields.map(field =>  toAttributeLabel(field, translator)), " " + "\u0026" + " ", 5)
  }

  function toInputDescription(parentName, inputFields, translator) {
    const parent = parentName !== "" ? " " + t("descriptions.task.for") + " " + translator.toLabel(processKey, parentName, parentName) : ""
    return toAttributesDescription(inputFields, translator) + parent
  }

  function toInputDescriptions(inputFields, translator) {
    return groupByArray(inputFields, 'parent')
      .map(entry => toInputDescription(entry.key, entry.values, translator))
  }

  function toMultipleDescription(multipleField, translator) {
    let [multipleFields, inputFields] = partitionMultiple(multipleField.items)
    let attributes = toAttributesDescription(inputFields, translator)
    let separator  = attributes !== "" ?  " " + t("descriptions.task.for") + " " : ""
    return attributes + separator + t("descriptions.task.multiple") + translator.toLabel(processKey, multipleField.elementName, multipleField.elementName)
  }

  function toMultipleDescriptions(multipleFields, translator) {
    return multipleFields.flatMap(multipleField => {
      let [multipleFields, inputFields] = partitionMultiple(multipleField.items)

      if (inputFields.length > 0)
        return [toMultipleDescription(multipleField, translator)]
      else
        return toMultipleDescriptions(multipleField.items, translator)
    })
  }

  function toItemDescriptions(fields, translator) {
    let [multipleFields, inputFields] = partitionMultiple(fields)
    let multipleDescriptions = toMultipleDescriptions(multipleFields, translator)
    let inputDescriptions = toInputDescriptions(inputFields, translator)
    return inputDescriptions.concat(multipleDescriptions)
  }

  if (!Array.isArray(documentation?.structure))
    return ""

  const sentences = toItemDescriptions(documentation.structure || [], translator)
  console.log("sentences=%o => %s", sentences, !sentences.length == 0)
  if (sentences.length > 0)
    return translator.t("descriptions.task.description", { sentences: F.toList(sentences, " " + translator.t("descriptions.task.and") + " ", 5) } );
  else
    return translator.t("descriptions.task.nodescription");
}

export function createShortTaskDescription(translator, task) {
  const documentation = task.documentation
  const processKey = task.processDefinition.key

  function toForItemDescriptions(fields, translator) {
    let [multipleFields, inputFields] = partitionMultiple(fields)
    const toMultipleForName = (field) => t("descriptions.task.multiple") + " " + getForName(translator, task)
    const toSingleForName   = (field) => translator.toLabel(processKey, field.parent, field.parent)
    const multipleForNames  = multipleFields.map(toMultipleForName)
    const inputForNames     = inputFields.filter(entry => entry.parent).map(toSingleForName)

    return _.uniq(inputForNames.concat(multipleForNames))
  }

  if (!Array.isArray(documentation?.structure))
    return ""

  const sentences = toForItemDescriptions(documentation.structure || [], translator)
  if (!sentences.length == 0)
    return translator.t("descriptions.task.shortdescription", { sentences: F.toList(sentences, " " + t("descriptions.task.and") + " ", 5) } );
  else
    return translator.t("descriptions.task.nodescription");
}
