import { MultipleHeaderTableCellContents } from 'components/form/fields/multiple';
import { MultipleRowProps } from 'components/form/fields/multiple/MultipleTable';
import {
    useMultipleColumns, MultipleColumnProps
} from 'components/form/fields/utils/multiple-utils';
import { useConfig } from 'hooks/config';
import { useFieldInfo } from 'contexts/FieldInfoContext';
import { useFormInfo } from 'hooks/form';
import { useTranslator } from 'hooks/translator';
import { MultipleColumn, MultipleRows, MultipleSelected } from 'types/multiple';
import { SetState } from 'types/react';

import { TableCell } from '@mui/material';
import { MultipleFeatures } from '../InputMultipleField';

const MultipleTableHeaderFieldCells = ({rows, features, selected, setSelected}: MultipleRowProps) => {
  const formInfo                 = useFormInfo()
  const {translator}             = useTranslator()
  const {info}                   = useFieldInfo()
  const {props: {taskRendering}} = useConfig()

  const props: MultipleColumnProps = {
    taskRendering,
    formInfo,
    translator,
    rpath: info.rpath,
    processKey: formInfo.processDefinition.key, 
    rows,
    ...info.field
  }

  const columns = useMultipleColumns( rows )
  return <>
    { columns.map((column, index) =>
        <MultipleTableHeaderCell
          key={index}
          index={index}
          column={column}
          rows={rows}
          features={features}
          selected={selected}
          setSelected={setSelected}
        />
      )
    }
  </>
}

type MultipleHeaderCellProps = {
  index:       number
  column:      MultipleColumn
  rows:        MultipleRows, 
  features:    MultipleFeatures,
  selected:    MultipleSelected, 
  setSelected: SetState<MultipleSelected>
}

const MultipleTableHeaderCell = ({index, column, rows, features, selected, setSelected}: MultipleHeaderCellProps ) => {
  const tableCellStyle  = (index: number, selected: MultipleSelected) => index === 0 && selected.length ? {paddingLeft: "0px"} : {}
  return (
    <TableCell key={index} style={{ whiteSpace: 'nowrap' }} sx={{ ...tableCellStyle(index, selected)}}>
      <MultipleHeaderTableCellContents
        id={index}
        rows={rows}
        column={column}
        features={features}
        selected={selected}
        setSelected={setSelected}
      />
    </TableCell>
  )
}

export default MultipleTableHeaderFieldCells
