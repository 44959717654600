import Fallback from 'components/common/Fallback';
import TaskSubmit from 'components/form/task/TaskSubmit';
import TaskFields from 'components/form/task/window/TaskFields';
import TaskSave from 'components/form/task/window/TaskSave';
import { embeddedBasedOn } from 'components/form/task/window/TaskWindow';
import { focusOnField } from 'components/form/utils/field-utils';
import { getFocusPathFromValues } from 'components/form/utils/input-utils';
import { setStepIconTabIndices } from 'components/form/utils/task-utils';
import { RenderValue } from 'components/render';
import { usePagePanelContext, useWindowPageContext } from 'components/wm';
import RenderContextProvider from 'contexts/RenderContext';
import ValuesProvider from 'hooks/values';
import { Form as FormikForm, Formik } from 'formik';
import { useFormInfo } from 'hooks/form';
import { FormikFormInfo, FormikType } from 'hooks/submit';
import { useValues } from 'hooks/values';
import { useEffect, useMemo, useRef } from 'react';
import Types from 'types/types';
import { isEmptyValue } from 'utils/utils';

import { Box } from '@mui/material';

type TaskFormProps = {
  onSubmit: (values: any, formInfo: FormikFormInfo) => void 
}

/** This is a step with error handling */
function TaskForm(props: TaskFormProps) {
  return (
    <Fallback type='component' name={"form"}>
      <ValuesProvider>
        <RenderContextProvider context="step">
          <TaskFormContents {...props} />
        </RenderContextProvider>
      </ValuesProvider>
    </Fallback>
  )
}

/** Render the contents of a step */
const TaskFormContents = ({onSubmit}: TaskFormProps) => {
  const { values } = useValues()
  const formikRef  = useRef<FormikType | undefined>()
  const formInfo   = useFormInfo()

  // let the window manager know about formik
  const { ref } = useWindowPageContext()
  ref.current.data.formikRef = formikRef

  function handleSubmit(values: any) {
    const formik = formikRef.current
    onSubmit(values, {...formInfo, formik: formik! })
  }

  const autoFocus = async () => {
    const focusPath = getFocusPathFromValues(formInfo, values)
    if (focusPath) {
      console.log("Focus on path: %o", focusPath)
      focusOnField(focusPath)
    }
  }

  useEffect(() => {
    setStepIconTabIndices()
  },[])

  useEffect(() => {
    autoFocus()
  },[])

  // add the save button to the window pane
  const {setButtons} = usePagePanelContext()
  useEffect(() => { 
    if (!formInfo.isStartForm) {
      setButtons(buttons => {
        if (!buttons.find(button => button.id == "save")) {
          return [...buttons,
          {
            id: "save",
            component: <TaskSave formikRef={formikRef} />
          }
          ]
        } else return buttons
      })
    }
  }, [])

  return (
    <>
      <Formik
        // @ts-ignore
        innerRef={formikRef}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{...values}}
        onSubmit={handleSubmit}
      >
        <FormikForm noValidate style={{
          display: "flex", flexDirection: "column", flexGrow: 1, maxWidth: "100%", overflow: "auto", maxHeight: "100%"
          , minHeight: 0, height: "100%"
        }}>
          <TaskBody />
        </FormikForm>
      </Formik>
    </>
  )
}

const TaskBody = () => {
  const formInfo        = useFormInfo()
  const nonSubmitFields = useMemo(() => 
    formInfo.form.fields.filter(field => !Types.isSubmitField(formInfo, field)),
    [formInfo]
  )
  const submitFields    = useMemo(() => 
    formInfo.form.fields.filter(field => Types.isSubmitField(formInfo, field)),
    [formInfo]
  )

  return (
    <Box className='task-body' sx={{ display: "flex", flexGrow: 1, flexDirection: "column", width: "100%", paddingX: "20px", overflow: "auto" }}>
      <Box className='task-body-fields' sx={{ padding: "2px", display: "flex", flexGrow: 1, flexDirection: "column", width: "100%", overflow: "auto" }}>
        <TaskBasedOn />
        <TaskFields fields={nonSubmitFields} />
      </Box>
      <Box className='task-body-submits' sx={{ padding: "2px", display: "flex", flexGrow: 0, flexShrink: 0, flexDirection: "column", width: "100%"}}>
        <TaskFields fields={submitFields} />
      </Box>
      <TaskSubmit />
    </Box>
  )
}

const TaskBasedOn = () => {
  const formInfo  = useFormInfo()
  const basedOn   = formInfo.basedOn
  const embedded = useMemo(() => embeddedBasedOn(formInfo, basedOn), [formInfo])

  if (!embedded || isEmptyValue(basedOn))
    return null

  return (
    <>
      <Box sx={{margin: "20px"}}>
        <RenderValue>
          {basedOn} 
        </RenderValue>
      </Box>
    </>
  )
}
 
export default TaskForm;
