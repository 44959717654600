import LoadingIcon from 'components/common/LoadingIcon';
import { useContentOnClick } from 'hooks/content';
import { Attachment } from 'types/graphql';

import { Download as DownloadIcon } from '@mui/icons-material';
import { Box, Card, Tooltip } from '@mui/material';
import { RenderValue } from 'components/render';
import { openInNewTab } from 'utils/utils';
import { download } from 'utils/download';

const FileTile = ({attachment}: {attachment: Attachment}) => {
  const {
    content, loading, 
    loadOnClick, downloadOnClick 
  } = useContentOnClick({id: attachment.__id, filename: attachment.filename, type: 'content', mimeType: attachment.contentType})

  const onClick = (e: any) => {
    //openInNewTab(Runtime.content(attachment.__id)) // this does not work because there is no way to authenticate
    loadOnClick(e).then(() => {
      if (content.type.startsWith("image/") || content.type == "application/pdf")
        openInNewTab(content.url)
      else download(content.url, attachment.filename)
    })
  }

  return (
    <Tooltip title={attachment.filename} placement='left'>
      <Card
        onClick={onClick}
        sx={{
          cursor: !loading ? "pointer" : "wait",
          background: (theme) => theme.palette.grey[300],
          height: "20px",
          width: "180px",
          "&:hover": {
            background: (theme) => theme.palette.grey[400],
          }
        }}
      >
        <Box sx={{position: "relative"}}>
          <Box
            sx={{
              opacity: 0.6,
              position: "absolute",
              width: "160px",
              height: "20px",
              marginLeft: "3px",
              display:"flex",
              justifyContent:"left",
              alignItems: "center"
            }}
          >
            <RenderValue value={{__kind: 'filename', __value: attachment.filename}} sx={{maxWidth: "250px"}}/>
          </Box>

          
            <Box sx={{display: "block", position: "absolute", width: "180px", height: "20px", opacity: 0,
              "&:hover": {opacity: "1 !important"}}}
            >
                <TileHover downloadOnClick={downloadOnClick} loading={loading} attachment={attachment} />
            </Box>
        </Box>
      </Card>
    </Tooltip>
  )
}

const TileHover = ({downloadOnClick, loading}: {downloadOnClick: (e: any) => void, loading: boolean, attachment: Attachment}) => {
  return (
    <Box sx={{display: "flex", flexDirection: "column", height: "100%", opacity: 1}}>
      {/* <Typography noWrap textOverflow={"ellipsis"} fontSize={10}>
        {removeExtension(attachment.filename)}
      </Typography> */}
      <Box sx={{flexGrow: 1}} />
      <Box sx={{display: "flex", width: "100%", marginTop: "-4px", marginLeft: "5px", justifyContent: "right"}}>
        <LoadingIcon  icon={DownloadIcon}
                      iconProps={{iconSize: 'small', iconButtonSize: 'small'}}
                      tooltipProps={{title:"Download"}}
                      onClick={downloadOnClick}
                      loading={loading} />
      </Box>
    </Box>
  )
}

export default FileTile
