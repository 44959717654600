import { Box }             from '@mui/material';
import FileTile            from 'components/tile/FileTile';
import DownloadFilesButton from 'components/tile/DownloadFilesButton';

const FileTiles = ({attachments}) => {
  if (attachments?.length == 1) {
    return <FileTile attachment={attachments[0]}/>
  }
  else if (attachments?.length) {
    return (
      <Box sx={{display: "flex",
                maxWidth: "100%",
                flexDirection: "row",
                alignItems: "flex-start",
                flexWrap: "wrap",
                background: (theme) => theme.palette.grey[200],
                borderRadius: "10px",
                marginRight: "10px",
                flexGrow: 3}}>
        <DownloadFilesButton attachments={attachments}/>
        { attachments.map((attachment, index) => (
            <Box key={index} sx={{marginLeft: "10px", marginTop: "5px", marginBottom: "5px"}}> 
              <FileTile key={index} attachment={attachment} />
            </Box>
          ))
        }
      </Box>
    )
  }
  else return null
}

export default FileTiles
