import { useTranslation }           from 'react-i18next';
import { useFieldInfo }             from 'contexts/FieldInfoContext';
import {
  deleteSelectedRows,
  copySelectedRows }                from 'components/form/fields/utils/multiple-utils';
import { CopyButton, DeleteButton } from 'components/form/fields/multiple';
import { setStepIconTabIndices }    from 'components/form/utils/task-utils';

const MultipleTableHeaderActions = ({features, rows, selected, setSelected}) => {
  const { t }        = useTranslation()
  const { augProps } = useFieldInfo()

  const handleClickCopy = (_event) => {
    const at = (selected.length ? Math.max(...selected) : 0) + 1
    augProps.setValue(copySelectedRows(rows, selected, at))
    setStepIconTabIndices(1000)
  }

  const handleClickDelete = (_event) => {
    augProps.setValue(deleteSelectedRows(rows, selected))
    setSelected([])
  }

  return selected.length
    ? <>
        {features.add ? (
          <CopyButton
            id='rows-copy-button'
            title={t('multiple.copyrows')}
            onClick={handleClickCopy}
          />
        ) : null}
        {features.remove ? (
          <DeleteButton
            id='rows-delete-button'
            disabled={selected.length === rows.length}
            disabledTitle={t("multiple.disabled.deleteallrows")}
            title={t('multiple.deleterows')}
            onClick={handleClickDelete}
          />
        ) : null}
      </>
    : null
}

export default MultipleTableHeaderActions
